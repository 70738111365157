import React, { Component } from 'react';
import Style from './Profile.module.css';
import { connect } from 'react-redux';
import {
  updateUserInfo,
  setError,
  getCustomerAlertConfig,
  updateCustomerAlertConfig,
} from '../store/entities/customers';
import ModalPopup from '../components/common/ModalPopup';
import { Form } from 'react-bootstrap';
import { hideSafeOnMsg, hideSafeOffMsg } from '../utilities/config';
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper';
import { validateEmail } from '../utilities/vaildations';

class AlertConfig extends Component {
  state = {
    alertConfig: {
      label: 'PIXM',
      email: '',
      phone: '',
      isLabelConfigured: false,
      isContactInfoConfigured: false,
      showProcessingBanner: false,
      showProcessingBar: false,
      hideThisIsSafeButton: false,
    },
    progressBarValue: 'None',
    isActive: false,
    selectOptions: ['Full-size banner', 'Thin progress bar', 'None'],
  }

  componentDidMount() {
    this.props.getCustomerAlertConfig(this.props.match.params.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.alertConfig !== this.props.alertConfig) {
      const {
        label,
        email,
        phone,
        isLabelConfigured,
        isContactInfoConfigured,
        showProcessingBanner,
        showProcessingBar,
        hideThisIsSafeButton,
      } = this.props.alertConfig
      this.setState({
        alertConfig: {
          label,
          email,
          phone,
          isLabelConfigured,
          isContactInfoConfigured,
          showProcessingBanner,
          showProcessingBar,
          hideThisIsSafeButton,
        },
      })
      if (showProcessingBanner === false && showProcessingBar === false) {
        this.setState({ progressBarValue: 'None' })
      }
      if (showProcessingBanner === true && showProcessingBar === true) {
        this.setState({ progressBarValue: 'Thin progress bar' })
      }
      if (showProcessingBanner === true && showProcessingBar === false) {
        this.setState({ progressBarValue: 'Full-size banner' })
      }
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    const { alertConfig } = this.state
    alertConfig[name] = value
    this.setState({ alertConfig })
  }

  handleSelectChange = (option) => {
    const { alertConfig } = this.state
    if (option === 'None') {
      alertConfig.showProcessingBanner = false
      alertConfig.showProcessingBar = false
    }
    if (option === 'Thin progress bar') {
      alertConfig.showProcessingBanner = true
      alertConfig.showProcessingBar = true
    }
    if (option === 'Full-size banner') {
      alertConfig.showProcessingBanner = true
      alertConfig.showProcessingBar = false
    }
    this.setState({ alertConfig })
    this.setState({ progressBarValue: option })
    this.setState({ isActive: false })
  }

  handleCheck = (event) => {
    const { name, checked } = event.target
    const { alertConfig } = this.state

    alertConfig[name] = checked

    if (name == 'isLabelConfigured' && checked === false)
      alertConfig.label = 'PIXM'

    if (name == 'isContactInfoConfigured' && checked === false) {
      alertConfig.email = ''
      alertConfig.phone = ''
    }

    // if (name == 'showProcessingBanner'  && checked === false) {
    //   alertConfig.showProcessingBar = false
    // }

    this.setState({ alertConfig })
  }

  handleUpdateInfo = () => {
    const { alertConfig } = this.state
    let error = '';
    if (alertConfig.label && alertConfig.label.length > 25) {
      error = 'Label can have maximum length of 25 characters';
      this.props.setError(error);
    }

    // if (alertConfig.email) {
    //   if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(alertConfig.email,))
    //     this.props.setError('Email address is invalid')
    // }

    if(alertConfig.email && !validateEmail(alertConfig.email)) {
        error = 'Email address is invalid';
        this.props.setError(error);
    }

    if (alertConfig.phone && !/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(alertConfig.phone)){
      error = 'Phone number is invalid. Phone number should be in this format ( 1234567890 )';
      this.props.setError(error);
    }
    
    if(!error) {
        this.props.updateCustomerAlertConfig(this.props.match.params.id, {
          ...this.props.alertConfig,
          ...this.state.alertConfig,
        })
    }
  }

  toggelDropdown = () => {
    this.setState({
      isActive: !this.state.isActive,
    })
  }

  handleShowSafeChange = () => {
    const { alertConfig } = this.state
    alertConfig.hideThisIsSafeButton = !alertConfig.hideThisIsSafeButton
    this.setState(alertConfig)
  }

  render() {
    const {
      label,
      email,
      phone,
      isLabelConfigured,
      isContactInfoConfigured,
      showProcessingBanner,
      showProcessingBar,
      hideThisIsSafeButton,
    } = this.state.alertConfig
    return (
      <div className={`mt-4`}>
          <React.Fragment>
            <div className={`${Style.profileFontStyle}`}>Config</div>
            <div className={`${Style.containerStyle} pb-5 pl-4 mt-2`}>
            {
              this.props.loading ?
              <LoaderWrapper loading={this.props.loading} isFlex={true} styles={{height: '500px'}} > </LoaderWrapper>
              :
              <>       
                <div className={`${Style.textStyle} pt-4`}>
                  <div>
                    <input type="checkbox" checked={isLabelConfigured} name="isLabelConfigured" onChange={this.handleCheck} />{' '}
                    <span>Configure Label</span>
                  </div>
                  <input disabled={!isLabelConfigured} value={label} name="label" className={`${Style.inputStyle} mt-3 focus-none ${ isLabelConfigured ? '' : Style.inputTextGray }`} onChange={this.handleChange} />
                </div>

                <div className={`${Style.textStyle} pt-4`}>
                  <div>
                    <input
                      type="checkbox"
                      name="isContactInfoConfigured"
                      checked={isContactInfoConfigured}
                      onChange={this.handleCheck}
                    />{' '}
                    <span>Configure Contact Info</span>
                  </div>
                  <input
                    disabled={!isContactInfoConfigured}
                    value={email}
                    name="email"
                    placeholder="Email"
                    className={`${Style.inputStyle} mt-3 focus-none ${
                      isContactInfoConfigured ? '' : Style.inputTextGray
                    }`}
                    onChange={this.handleChange}
                  />
                  <input
                    disabled={!isContactInfoConfigured}
                    value={phone}
                    name="phone"
                    placeholder="Phone number"
                    className={`${Style.inputStyle} mt-3 focus-none ${
                      isContactInfoConfigured ? '' : Style.inputTextGray
                    }`}
                    onChange={this.handleChange}
                  />
                </div>

                <div className={`${Style.textStyle} pt-4`}>
                  <span>Progress Bar</span>
                  <div className={Style.dropdown}>
                    <div
                      className={Style.dropdownBtn}
                      onClick={this.toggelDropdown}
                    >
                      {this.state.progressBarValue}
                    </div>
                    {this.state.isActive && (
                      <div className={Style.dropdownContent}>
                        {this.state.selectOptions.map((option) => (
                          <div
                            key={option}
                            onClick={(e) => {
                              this.handleSelectChange(option)
                            }}
                            className={Style.dropdownItem}
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className={`${Style.textStyle} pt-4 d-flex`}>
                  <div>
                    Hide 'This is safe'
                    <h6 style={{color: '#9897A9'}}>
                      {hideThisIsSafeButton ? hideSafeOnMsg : hideSafeOffMsg}
                    </h6>
                  </div>
                  <div className="pl-5 pt-2">
                    <Form.Check
                      type="switch"
                      id="defaultSwitch"
                      checked={hideThisIsSafeButton}
                      value={hideThisIsSafeButton}
                      onChange={this.handleShowSafeChange}
                    />
                  </div>
                </div>

                {/* <div className={`${Style.textStyle} pt-4`}>
                  <input
                    type="checkbox"
                    name="showProcessingBanner"
                    checked={showProcessingBanner}
                    onChange={this.handleCheck}
                  />{' '}
                  <span>Show Processing Banner</span>
                </div>

                {showProcessingBanner && (
                  <div className={`${Style.textStyle} pt-4`}>
                    <input
                      type="checkbox"
                      name="showProcessingBar"
                      checked={showProcessingBar}
                      onChange={this.handleCheck}
                    />{' '}
                    <span>Show Only Processing Bar</span>
                  </div>
                )} */}

                <div className={`${Style.border} mt-3`} />
                <div className={`mt-5`}>
                  <button
                    onClick={this.handleUpdateInfo}
                    className={`${Style.submitButton} focus-none`}
                  >
                    Update Info
                  </button>
                  <button className={`${Style.cancelButton} ml-3 focus-none`}>
                    Cancel
                  </button>
                </div>
              </>
            }
            </div>
            </React.Fragment>
        {this.props.statusCode && (
          <ModalPopup
            statusCode={this.props.statusCode}
            msg={this.props.userMsg}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  email: state.entities.user.email,
  fullName: state.entities.user.name,
  userId: state.entities.user.id,
  tenantId: state.entities.user.tenantId,
  needRefreshToken: state.entities.customers.needRefreshToken,
  userMsg: state.entities.customers.userMsg,
  statusCode: state.entities.customers.statusCode,
  refreshToken: state.entities.user.refreshToken,
  loading: state.entities.customers.loading,
  alertConfig: state.entities.customers.alertConfig,
})

const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (userId, body) => dispatch(updateUserInfo(userId, body)),
  getCustomerAlertConfig: (customerId) =>
    dispatch(getCustomerAlertConfig(customerId)),
  updateCustomerAlertConfig: (customerId, data) =>
    dispatch(updateCustomerAlertConfig(customerId, data)),
  setError: (error) => dispatch(setError(error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertConfig)
