import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { logout } from '../../../store/entities/user'
import { clearAllEvents } from '../../../store/entities/events'
import { globalViewText, isGlobalView } from '../../../utilities/config'

import profileSvg from '../../../assets/profile.svg'
import profileSelectedSvg from '../../../assets/profile_selected.svg'
import groupSvg from '../../../assets/group.svg'
import settingsSvg from '../../../assets/settings.svg'
import logoutSvg from '../../../assets/logout.svg'
import peopleSvg from '../../../assets/people-black.svg'
import deployLogo from '../../../assets/deploy-Icon.svg'
import visible from '../../../assets/zoom.svg'
import socSvg from '../../../assets/soc.svg'
import './profilePopover.scss'
import { GLOBAL_VIEW_DISABLED_OPTIONS, GLOBAL_VIEW_DISABLED_MESSAGE, GLOBAL_VIEW_DISABLED_PATHS } from '../../../utilities/globalViewDisabled'


class ProfilePopover extends Component {
  state = {
    contentClass: ['profile-popover', 'profile-popover-remove'],
    isOpened: false,
  }

  profileOptions = [
    {
      name: 'Threat Feed',
      icon: visible,
      handleClick: () =>
        this.props.history.push({
          pathname: `/${
            isGlobalView(this.props.selectedCustomer.organizationName)
              ? 'tenants'
              : 'customers'
          }/${this.props.selectedCustomer.id}/incidents`,
        }),
    },
    {
      name: 'SOC Integration',
      icon: socSvg,
      handleClick: () =>
        this.props.history.push({
          pathname: `/soc-integration`,
          state: { tab: 'soc integration' },
        }),
    },
    {
      name: 'Deployment',
      icon: deployLogo,
      handleClick: () =>
        this.props.history.push({
          pathname: `/${
            isGlobalView(this.props.selectedCustomer.organizationName)
              ? 'tenants'
              : 'customers'
          }/${this.props.selectedCustomer.id}/deployment`,
          state: { tab: 'deployment' },
        }),
    },
    {
      name: 'License Management',
      icon: groupSvg,
      handleClick: () =>
        this.props.history.push({
          pathname: `/${
            isGlobalView(this.props.selectedCustomer.organizationName)
              ? 'tenants'
              : 'customers'
          }/${this.props.selectedCustomer.id}/license-management`,
          state: { tab: 'license Management' },
        }),
    },
    {
      name: 'Settings',
      icon: settingsSvg,
      handleClick: () =>
        this.props.history.push(
          `/customers/${this.props.selectedCustomer.id}/settings`,
        ),
    },
    {
      name: 'Logout',
      icon: logoutSvg,
      handleClick: () => {
        this.props.clearAllEvents()
        this.props.logout()
        //window.location.replace(`${process.env.REACT_APP_LOGIN_WINDOW_URL}`)
      },
    },
  ]

  constructor(props) {
    super(props)
    this.updateWrapperRef = React.createRef()
  }

  toggleGlobalViewSettings = () => {
    const { orgList, selectedCustomer } = this.props;
    // check if organization list has 'Global view' in it
    const hasGlobalView = orgList.some(item => item.organizationName === globalViewText);
    if (hasGlobalView) {
      let isGlobal = isGlobalView(selectedCustomer.organizationName);
      const { multiGroup } = selectedCustomer;
      this.profileOptions.forEach((item) => {
        const isLicense = item.name === 'License Management';
        item.isDisabled = isGlobal && GLOBAL_VIEW_DISABLED_OPTIONS.includes(item.name) || (isLicense && multiGroup);
        item.tooltip = item.isDisabled ? `${GLOBAL_VIEW_DISABLED_MESSAGE} ${item.name}` : '';
      });
    }
  }

  componentDidMount() {
    if (this.props.userRole === 'TenantAdmin') {
      this.profileOptions.splice(0, 0, {
        name: 'MSP Center',
        icon: peopleSvg,
        handleClick: () => this.props.history.push(`/tenant-admin`),
      })
      this.profileOptions.join()
    }

    if(this.props.userRole == 'PixmAdmin'){
      this.profileOptions.splice(5, 0 ,
        {
          name: 'Advanced Settings',
          icon: settingsSvg,
          handleClick: () => {
            this.props.history.push(`/advanced-settings`)
          },
        })
        this.profileOptions.join()
    }
      
  }

  componentDidUpdate(prevProps) {
    const { selectedCustomer } = this.props;
    if (selectedCustomer && selectedCustomer !== prevProps.selectedCustomer) {
      this.toggleGlobalViewSettings();
    }
  }

  toggle = () => {
    let { contentClass, isOpened } = this.state
    if (contentClass.indexOf('profile-popover-remove') === -1) {
      contentClass.push('profile-popover-remove')
      isOpened = false
    } else {
      contentClass.pop()
      isOpened = true
    }
    this.setState({ contentClass, isOpened })
  }

  handleClickOutside = (event) => {
    let { contentClass, isOpened } = this.state
    if (contentClass.indexOf('profile-popover-remove') === -1) {
      contentClass.push('profile-popover-remove')
      isOpened = false
    }
    this.setState({ contentClass, isOpened })
  }

  render() {
    let preventClick = true
    if (this.props.selectedCustomer) {
      preventClick = false
    }
    return (
      <div
        className={'parent'}
        onClick={() => this.toggle()}
        tabIndex="0"
        onBlur={(e) => this.handleClickOutside(e)}
        style={{
          marginRight: '5px',
          padding: '3px',
          borderRadius: '50%',
          background: this.state.isOpened ? '#2d5fa7' : '#f7f7f7',
          cursor: 'pointer',
        }}
      >
        <img
          src={this.state.isOpened ? profileSelectedSvg : profileSvg}
          alt=""
          style={{ height: '30px' }}
          srcSet=""
        />

        <div className={this.state.contentClass.join(' ')}>
          <div className="popover-arrow"></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              fontSize: '14px',
              fontFamily: 'Roboto',
            }}
          >
            {this.profileOptions.map((item) => (
              <div
                key={item.name}
                disabled={item.disabled}
                className="profile-option"
                onClick={() =>
                  (!preventClick || item.name === 'Logout') && !item.isDisabled
                    ? item.handleClick()
                    : null
                }
              >
                <div
                  style={{ padding: '5px', width: '15%' }}
                  title={item.tooltip}
                  className={item.isDisabled ? 'option-disabled' : null}
                >
                  <img src={item.icon} height="20px" alt="" srcSet="" />
                </div>
                <div
                  style={{ flexGrow: '1', padding: '5px' }}
                  title={item.tooltip}
                  className={item.isDisabled ? 'option-disabled' : null}
                >
                  {item.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      // <Dropdown>
      //   <Dropdown.Toggle
      //     bsPrefix="toggle"
      //     as={Link}
      //     to="#!"
      //     className="pe-3 ps-2 nav-link"
      //   >
      //     <img src={profileSvg} alt="" style={{ height: '20px' }} srcSet="" />
      //   </Dropdown.Toggle>
      //   <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
      //     <div className="bg-white rounded-2 py-2 dark__bg-1000">
      //     {this.profileOptions.map((item) => (
      //       <>
      //         <div data-tip data-for={item.name}>
      //           <Dropdown.Item
      //             key={item.name}
      //             onClick={() => (!preventClick || item.name === 'Logout') && !item.isDisabled ?  item.handleClick() : null}
      //             disabled={item.isDisabled}
      //           >{item.name}</Dropdown.Item>
      //         </div>
      //         {item.isDisabled &&
      //             <Tooltip id={item.name} data={item.tooltip} />
      //         }
      //       </>
      //     ))}
      //     </div>
      //   </Dropdown.Menu>
      // </Dropdown>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedCustomer: state.entities.customers.selected,
  userRole: state.entities.user.roles,
  orgList: state.entities.customers.list,
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  clearAllEvents: () => dispatch(clearAllEvents()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePopover),
)
