import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { formatDetectionDatetime } from '../utilities/datetime'
import { isGlobalView } from '../utilities/config'
import { loadEventById } from '../store/entities/events'

import backButton from '../assets/backButton.svg'
import screenshotSvg from '../assets/screenshot.svg'
import htmlSvg from '../assets/html.svg'
import captureSvg from '../assets/capture.svg'
import screenshotSelectedSvg from '../assets/screenshot_selected.svg'
import htmlSelectedSvg from '../assets/html_selected.svg'
import captureSelectedSvg from '../assets/capture_selected.svg'
import { CopyBlock, solarizedLight } from 'react-code-blocks'
import { deleteValue } from '../utilities/storeManager'
import './incidentDetails.scss'
import UpdatePopover from './common/popover/updatePopover'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper'

class IncidentDetails extends Component {
  state = {
    pageContent: '',
    selectedTab: 1,
    shouldRenderVisionTab: false,
  }

  loadPageContent = async () => {
    const { selectedEvent: event } = this.props;
    let pageContentURL = '';
    try {
      if (event && !this.state.pageContent) {
        pageContentURL = event.status == "Reported Phish" ? `${event.markedScreenshotURL.slice(0, -5)}.html` : (event.status == "Suspicious" || event.status == "Unknown") ? `${event.markedScreenshotURL.slice(0, -12)}.html` : event.pageContentURL; //constructing the html url from the markeScreenshotUrl
        const response = await axios.get(pageContentURL)
        this.setState({ pageContent: response.data })
      }
    }
    catch(err){
      console.error('Error in loading page content', err);
      this.setState({ pageContent: 'Error in loading HTML content' });
    }
  }

  componentDidMount() {
    const { selectedEvent: event, tokenRoles } = this.props
    let role = 'PixmAdmin'
    const accessibleRoles = role.split(',')
    const shouldShow = accessibleRoles.filter((value) =>
      tokenRoles.includes(value),
    )
    this.setState({
      shouldRenderVisionTab: shouldShow.length ? true : false,
    })
    if (event === null) {
      this.props.loadEventById(
        this.props.match.params.param3, 
        this.props.match.params.param2 || this.props.match.params.param1,
      )
    }
    else this.loadPageContent();
  }

  handleReturn = () => {
    deleteValue('fPositive')
    let { selectedCustomer } = this.props
    this.props.history.push(
      `${
        isGlobalView(selectedCustomer.organizationName)
          ? '/tenants'
          : '/customers'
      }/${selectedCustomer.id}/incidents`,
    )
  }

  componentDidUpdate(prevProps) {
    const { selectedEvent: event } = this.props
    if (event !== prevProps.selectedEvent) {
      this.loadPageContent();
    }
  }

  render() {
    const { selectedTab, shouldRenderVisionTab } = this.state
    const { selectedEvent: event, loading } = this.props
    // if (event !== null) {
    //   this.loadPageContent()
    // }
    return (
      <>
        {event && typeof event !== null && (
          <div className="pageContainer">
            <div className="wrapper">
              <div className="sections actionBar">
                <div className="pointer" onClick={this.handleReturn}>
                  <img width="25px" src={backButton} alt="" srcSet="" />
                  <span>Return to Overview</span>
                </div>
                <UpdatePopover
                  {...this.props}
                  role={this.props.tokenRoles}
                  event={event}
                />
              </div>
            <LoaderWrapper loading={loading} waitForData={false}>
              <div className="sections details">
                <div className="tableView">
                  <table id="detailsTable">
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <b>User Details :</b>
                        </td>
                      </tr>
                      <tr>
                        <td>User</td>
                        <td>
                          <b>
                            {event.osUserId ||
                              event.osComputerName ||
                              event.osuuid ||
                              event.email ||
                              'NA'}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Machine ID:</td>
                        <td>{event.osuuid ? event.osuuid : 'NA'}</td>
                      </tr>
                      <tr>
                        <td>Computer</td>
                        <td>
                          {event.osComputerName ? event.osComputerName : 'NA'}
                        </td>
                      </tr>
                      <tr>
                        <td>Opened</td>
                        <td>
                          {formatDetectionDatetime(event.detectionTime)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Threat Details :</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Brand</td>
                        <td>
                          <b>{event.brands ? event.brands : 'NA'}</b>
                        </td>
                      </tr>
                      {shouldRenderVisionTab && (
                        <tr>
                          <td>Status</td>
                          <td>
                            <span
                              className={event.status === "Verified Phish" ? 
                                "verifyText" : event.status === "Reported Phish"?
                                "reportedPhishText" : "unverifyText"}
                            >
                              <b>
                                {/* {event.triagedEvent ? 'Verified Phish'
                                  : event.type == 3 ?'Reported Phish' 
                                  : 'Suspicious'} */}
                                {event.status}
                              </b>
                            </span>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>IP</td>
                        <td>{event.domainIPAddress || 'NA'}</td>
                      </tr>
                      <tr>
                        <td>Url</td>
                        <td>
                          <span>{event.url}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="divider"></div>
                <div className="tabView">
                  <div className="tabs pointer">
                    <div
                      className="tab rightLine"
                      onClick={() => this.setState({ selectedTab: 1 })}
                    >
                      <img
                        src={
                          selectedTab === 1
                            ? screenshotSelectedSvg
                            : screenshotSvg
                        }
                        width="25px"
                      />
                      <span style={{ color: selectedTab === 1 && '#2d5fa7' }}>
                        Screenshot
                      </span>
                    </div>
                    <div
                      className="tab rightLine"
                      onClick={() => this.setState({ selectedTab: 2 })}
                    >
                      <img
                        src={selectedTab === 2 ? htmlSelectedSvg : htmlSvg}
                        width="25px"
                      />
                      <span style={{ color: selectedTab === 2 && '#2d5fa7' }}>
                        HTML
                      </span>
                    </div>
                    <div
                      className="tab"
                      onClick={() => this.setState({ selectedTab: 3 })}
                    >
                      <img
                        src={
                          selectedTab === 3 ? captureSelectedSvg : captureSvg
                        }
                        width="25px"
                      />
                      <span style={{ color: selectedTab === 3 && '#2d5fa7' }}>
                        Vision Analysis
                      </span>
                    </div>
                  </div>
                  <div className="dataView">
                    {selectedTab === 1 && event.resizedScreenshotURL && (
                      <div className="imageContainer">
                        <img
                          src={event.resizedScreenshotURL}
                          className={
                            event.deviceType === 'D' ? 'dataImg' : 'mobileImg'
                          }
                        />
                      </div>
                    )}
                    {selectedTab === 1 &&
                      !event.resizedScreenshotURL &&
                      'NO DATA'}
                    {selectedTab === 2 && (
                      <div className="codeblock">
                        <CopyBlock
                          language="html"
                          text={this.state.pageContent}
                          showLineNumbers={true}
                          theme={solarizedLight}
                          wrapLines={true}
                          codeBlock
                        />
                      </div>
                    )}
                    {selectedTab === 3 && event.markedScreenshotURL && (
                      <div className="imageContainer">
                        <img
                          src={event.markedScreenshotURL}
                          alt=""
                          srcSet=""
                          className={
                            event.deviceType === 'D' ? 'dataImg' : 'mobileImg'
                          }
                        />
                      </div>
                    )}
                    {selectedTab === 3 &&
                      !event.markedScreenshotURL &&
                      'NO DATA'}
                  </div>
                </div>
              </div>
            </LoaderWrapper>
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.entities.events.loading,
  selectedEvent: state.entities.events.selected,
  selectedCustomer: state.entities.customers.selected,
  tokenRoles: state.entities.user.roles,
})

const mapDispatchToProps = (dispatch) => ({
  loadEventById: (eventId, customerId) =>
    dispatch(loadEventById(eventId, customerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetails)
